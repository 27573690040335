import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//redux
import { errors } from "../../../../store/survey/survey.slice";
import { checkIfUpdate } from "../../../../store/survey/survey.actions";
import { putResponse } from "../../../Survey/validates";

//styles
import "../../../../assets/css/survey/nps.css";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

//itens de pergunta
const NPSComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const listError = useSelector(errors);
  const [npsValue, setNPSValue] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);

    if (surveyData.user.responses[data.id]) {
      setNPSValue(surveyData.user.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  const setNPSResponse = (num) => {
    let surveyData = getStorage(surveyid, true);
    surveyData.user.responses[data.id] = num;
    setStorage(surveyid, surveyData, true);
    setNPSValue(num);
    putResponse(surveyid);
    dispatch(checkIfUpdate(surveyid, data.id));
  };

  const checkTypeSelectdItem = (key) => npsValue === key;

  let designCustom = [];

  //aplicando bordas redondas
  if (data.properties && data.properties.rounded) {
    designCustom.push("rounded");
  }

  //aplicando sombra
  if (data.properties && data.properties.shadow) {
    designCustom.push("shadow");
  }

  //aplicando colorido
  if (data.properties && data.properties.colorfull) {
    designCustom.push("colorfull");
  }

  //aplicando três cores (tríade)
  if (data.properties && data.properties.triade) {
    designCustom.push("triade");
  }

  //aplicando padrão antigo
  if (data.properties && data.properties.standard) {
    designCustom.push("standard");
  }

  const styleNPS =
    data.properties && data.properties.baseColor
      ? `
      div.nps-${data.id} .nps-content .item {
        border: solid 1px ${data.properties.baseColor} !important;
      }
      div.nps-${data.id} .nps-content .item:hover {
        background-color: ${data.properties.baseColor}aa !important;
        color: #fff !important;
      }
      div.nps-${data.id} .nps-content .item.selected {
        background-color: ${data.properties.baseColor} !important;
        color: #fff !important;
      }
      `
      : ``;

  return (
    <div
      id="nps-component"
      className={`nps nps-${data.id} ${designCustom.join(" ")} ${
        data?.cssClass || ""
      }`}
    >
      <div className="nps-title">
        <Interweave content={data.title[language]} />{" "}
        {data.require && data.properties && data.properties.showAsterisk && (
          <span className="require">
            <sup>*</sup>
          </span>
        )}
      </div>

      {designCustom.includes("standard") ? (
        <div className="nps-content-standard">
          <div className="numbers">
            {data.options &&
              data.options.map((num, i) => {
                return (
                  <span key={`nps-options-label-${i}-${data.id}`}>{num}</span>
                );
              })}
          </div>
          <div className="radio">
            {data.options &&
              data.options.map((num, i) => {
                return (
                  <span key={`nps-options-${i}-${data.id}`}>
                    <input
                      type="radio"
                      name={`nps-options-${data.id}`}
                      id={`nps-options-${i}-${data.id}`}
                      value={num}
                      checked={checkTypeSelectdItem(num)}
                      onChange={() => setNPSResponse(num)}
                    />
                    <label htmlFor={`nps-options-${i}-${data.id}`}></label>
                  </span>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="nps-content">
          {data.options &&
            data.options.map((num, i) => {
              return (
                <div
                  key={`nps-options-${i}`}
                  className={`item ${
                    data.design !== "" ? "option-color-" + num : ""
                  } ${npsValue === num ? "selected" : ""}`}
                  alt={num}
                  onClick={() => setNPSResponse(num)}
                >
                  {num}
                </div>
              );
            })}
        </div>
      )}

      {(data.properties?.leftText || data.properties?.rightText) && (
        <div className="nps-texts">
          {data.properties?.leftText && (
            <div className="left">{data.properties.leftText}</div>
          )}
          {data.properties?.rightText && (
            <div className="right">{data.properties.rightText}</div>
          )}
        </div>
      )}

      <div className="nps-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
      {styleNPS !== "" && <style>{styleNPS}</style>}
    </div>
  );
};

export default NPSComponent;
